var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "userLayout" } }, [
    _c("div", { staticClass: "login_panle" }, [
      _c(
        "div",
        { staticClass: "login_panle_form" },
        [
          _c("div", { staticClass: "login_panle_form_title" }, [
            _c("img", {
              staticClass: "login_panle_form_title_logo",
              attrs: { src: _vm.$GIN_VUE_ADMIN.appLogo, alt: "" },
            }),
            _c("p", { staticClass: "login_panle_form_title_p" }, [
              _vm._v(_vm._s(_vm.$GIN_VUE_ADMIN.appName)),
            ]),
          ]),
          _vm._v(" "),
          _vm.$GIN_VUE_ADMIN.env != "development"
            ? _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "80%", margin: "10%" },
                          attrs: { type: "success" },
                          on: { click: _vm.oauth2 },
                        },
                        [_vm._v("OA授权登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$GIN_VUE_ADMIN.env == "development"
            ? _c(
                "el-form",
                {
                  ref: "loginForm",
                  attrs: { model: _vm.loginForm, rules: _vm.rules },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入用户名" },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-user",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: _vm.lock === "lock" ? "password" : "text",
                            placeholder: "请输入密码",
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        },
                        [
                          _c("i", {
                            class: "el-input__icon el-icon-" + _vm.lock,
                            attrs: { slot: "suffix" },
                            on: { click: _vm.changeLock },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "60%" },
                        attrs: {
                          name: "logVerify",
                          placeholder: "请输入验证码",
                        },
                        model: {
                          value: _vm.loginForm.captcha,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "captcha", $$v)
                          },
                          expression: "loginForm.captcha",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "vPic" }, [
                        _vm.picPath
                          ? _c("img", {
                              attrs: {
                                src: _vm.picPath,
                                width: "100%",
                                height: "100%",
                                alt: "请输入验证码",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.loginVerify()
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "40%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("登 录")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "50%", "margin-left": "2%" },
                          attrs: { type: "success" },
                          on: { click: _vm.oauthTest },
                        },
                        [_vm._v("测试OA授权登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "login_panle_right" }),
      _vm._v(" "),
      _c("div", { staticClass: "login_panle_foot" }, [
        _c("div", { staticClass: "copyright" }, [
          _vm._v("Copyright © " + _vm._s(_vm.curYear)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }